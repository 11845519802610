@use "@/styles/mixins/media-queries" as *;
@use "@/styles/typography/title-bold" as *;
@use "@/styles/mixins/font" as *;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 38.125rem;
  padding: 1.5rem 1rem 3rem 1rem;
  max-width: 105rem;
  width: 100%;
  background-image: var(--background-image);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 69%;

  @include mobile-only {
    background-position-x: 71%;
  }

  @include tablet-portrait-and-up {
    padding-left: 5rem;
    padding-top: 3.5rem;
    align-items: flex-start;
    height: 24.25rem;
    background-position-x: 57%;
    background-position-y: 95%;
  }

  @include tablet-landscape-and-up {
    padding-left: 10rem;
  }

  @include desktop-and-up {
    margin: 0 auto;
    padding-left: 15rem;
    align-items: flex-start;
    height: 24.25rem;
    background-position-x: 57%;
    background-position-y: 95%;
  }
}

.multipleLogos {
  @include mobile-only {
    height: 42.5rem;
    background-position-x: 70%;
  }
  @include tablet-portrait-and-up {
    background-position-x: 41%;
  }
  @include tablet-landscape-and-up {
    background-position-x: 0;
  }
}

.logosList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  flex: 1;
  gap: 1rem;
  position: relative;

  @include tablet-portrait-and-up {
    width: 50%;
    flex-wrap: nowrap;
  }
}

.logo {
  object-fit: contain;
}

.text {
  @include bold-druk;
  text-align: center;
  text-transform: uppercase;
  margin-top: 1.25rem;
  color: var(--primary-background-color);
  text-shadow: 0 0 8px var(--secondary-text-color);

  @include tablet-portrait-and-up {
    text-align: left;
    max-width: 25rem;
  }

  @include desktop-and-up {
    text-align: left;
    max-width: 37.5rem;
  }
}

.cta {
  width: 15.625rem;
  min-height: 3rem;
  margin-top: 1.5rem;

  /*
   * @note: the following 2 properties are a hack, currently we can not fix it since our title-2-bold-druk and title-2-bold
   * is bigger than the title-2-regular. In order to fix this hack we would need some design work, keep it in mind when
   * this component has to be reused.
   */

  font-weight: bold;
  text-transform: uppercase;

  @include tablet-portrait-and-up {
    margin-top: 1.375rem;
  }
  @include tablet-landscape-and-up {
    width: auto;
    max-width: 50%;
  }
}