@use "@/styles/mixins/media-queries" as *;
@use "@/styles/mixins/max-root-width";

.videoGalleryHeader {
  @include max-root-width.limit;
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: end;
}

.videoGalleryScrollingContainer {
  display: block;

  @media (hover: hover) {
    &:hover .videoGalleryVideoTeaserContainer:not(:hover) {
      opacity: 0.5;
    }
  }
}

.videoGalleryVideoTeaserContainer {
  transition: opacity 300ms ease-in-out;
}

.videoGalleryItemsCount {
  color: var(--label-text-color);

  @include tablet-landscape-and-up {
    display: none;
  }
}
