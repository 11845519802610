@use "../mixins/media-queries" as *;

.xpaContainer {
  :global {
    .xpaLayoutContainer:first-of-type {
      [class*='xpaLayoutContainerComponentResolver']:first-child {
        padding-top: 0;
      }
    }

    .xpaLayoutContainerFullWidth--proposition + .xpaLayoutContainerFullWidth--googleAdsPlaceholder {
      margin-top: 0;
    }

    .xpaLayoutContainerFullWidth--gallery {
      position: relative;

      :local {
        .patternContainer {
          @include desktop-and-up {
            display: block;
          }
        }
      }
    }

    .xpaLayoutContainerFullWidth--gallery ~ .xpaLayoutContainerFullWidth--gallery {
      :local {
        .patternContainer {
          @include desktop-and-up {
            display: none;
          }
        }
      }
    }
  }
}

.patternContainer {
  position: absolute;
  display: none;
  left: 0;
  top: calc(var(--containers-vertical-gap) * -1);
  width: calc(var(--root-side-padding) - 3rem);
  height: 37.125rem;

  path {
    fill: var(--elevated-background-color);
  }
}

.pattern {
  height: 100%;
  width: 100%;
}
