@use "@/styles/mixins/media-queries" as *;
@use "@/styles/mixins/max-root-width";

.matchGalleryHeader {
  @include max-root-width.limit;
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: end;
}

.matchGalleryItemsCount {
  color: var(--label-text-color);

  @include tablet-landscape-and-up {
    display: none;
  }
}
