@use "@/styles/mixins/media-queries" as *;
@use "@/styles/typography/title-medium" as *;
@use "@/styles/typography/title-bold" as *;

.container {
 position: relative;
  overflow: hidden;
  background-color: var(--background-reverse-color);
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  place-items: center;
  text-align: center;
  padding: 1.5rem 1.25rem;
  gap: 1.5rem;

  @include tablet-portrait-and-up {
    padding: 1.5rem 3.125rem;
  }
}

.pattern {
  position: absolute;
  inset: 0;
}



// Required to override the global styles of "button outline"
:global {
  @include dark-mode {
    :local {
      .content {
        background: linear-gradient(0deg, var(--gray-0) 0%, rgba(var(--gray-0-rgb), 0.5) 100%);
      }

      .container {
        .link {
          background-color: var(--primary-background-color);
        }
      }
    }
  }

  @include light-mode {
    :local {
      .content {
        background: linear-gradient(0deg, var(--black-0) 0%, rgba(var(--black-0-rgb), 0.80) 100%);
      }

      .container {
        .link {
          background-color: var(--primary-background-color);
        }
      }
    }
  }
}

.text {
  color: var(--headline-text-color);
}

.link {
  padding: 0.875rem 1rem;
}
